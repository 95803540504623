function formatNumber(number) {
  return currency(number, { separator: " ", decimal: ",", symbol: "z\u0142", pattern: `# !`, negativePattern: `-# !` }).format();
}
const { prestashop } = window;
prestashop.blockcart = prestashop.blockcart || {};
$.ready.then(() => {
  const cartAmount = $(".cart-item-amount");
  setInterval(() => {
    cartAmount.html(prestashop.cart.products_count);
  }, 1e3);
  prestashop.on("updateCart", (e) => {
    var _a, _b, _c;
    if (e && e.reason) {
      if (((_a = e.reason) == null ? void 0 : _a.linkAction) === "add-to-cart") {
        const modal = $("#addToCartModal");
        const id = e.reason.idProduct;
        const product = e.reason.cart.products.find((p) => p.id === id);
        console.log(product);
        const body = modal.find(".modal-body");
        const img = body.find(".product-img");
        const productInfo = body.find(".product-info");
        const productName = productInfo.find(".product-name");
        const producyCategory = productInfo.find(".product-category");
        const productDetails = productInfo.find(".product-details");
        const productReferenceValue = productDetails.find(".reference").find(".value");
        const price = body.find(".product-price");
        const priceNetto = price.find(".price_netto");
        const priceBrutto = price.find(".price_brutto");
        if ((_c = (_b = product == null ? void 0 : product.cover) == null ? void 0 : _b.medium) == null ? void 0 : _c.url) {
          img.html(`<img src="${product.cover.medium.url}" alt="${product.name}" width="120px" height="120px">`);
        } else {
          img.html('<img src="/img/p/pl-default-default_md.jpg" alt="placeholder" width="120px" height="120px">');
        }
        productName.html(product.name);
        producyCategory.html(product.manufacturer_name);
        productReferenceValue.html(product.reference);
        priceNetto.html(formatNumber(product.price_amount / ((product.rate + 100) / 100)) + " netto");
        priceBrutto.html(product.price + " brutto");
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: "add_to_cart",
          ecommerce: {
            currency: prestashop.currency.iso_code,
            value: product.price_amount & product.quantity,
            items: [
              {
                item_id: product.reference,
                item_name: product.name,
                item_brand: product.manufacturer_name,
                item_category: product.category,
                price: product.price_amount,
                quantity: product.quantity
              }
            ]
          }
        });
        modal.modal("show");
      }
    }
  });
});
